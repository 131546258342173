import React from "react";
import RESULTS from "../data/results.ts";
import CircuitCard from "../components/CircuitCard.js";

export default function Scores() {

  const marginStyle = {marginBottom: "4rem"}

  return(
    <div className="container container--large" style={marginStyle}>
      <h1 className='lifecraft-red'>Circuit Results</h1>
      {RESULTS.map(circuit => {
        return <CircuitCard circuit={circuit} />
      })}
    </div>
  )
}