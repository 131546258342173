import React from 'react';
import { TwitchEmbed } from 'react-twitch-embed';

const TwitchStream = ({player}) => {

  const containerStyle = { height: 720 }

    return(
      <div style={containerStyle}>
        <TwitchEmbed id={player.name} channel={player.channel} autoplay muted withChat={false} width="100%" height="100%" />
      </div>
    );
};

export default TwitchStream