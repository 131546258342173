import React from 'react'

export default function Footer() {
  const dancingOrc = './orc_dance.gif'
  const borderStyle = {borderTopWidth: 1, borderTopStyle: 'solid', borderColor: 'lightgray'}

  return (
    <footer class="flex-shrink-0 py-4 bg-dark text-white-50" style={borderStyle}>
      <div class="container text-center">
        <p className='footer-font'>
          <img src={dancingOrc} className="small-logo" alt="orc dance"/>
          What If Me Am That Kind Of Orc?</p>
      </div>
    </footer>
  )
}
