import React from "react";
import { Link } from "react-router-dom";


export default function Card({ player }) {
  const artURL = `class_art/${player.class}_art.png`
  const imageURL = `class_icons/${player.class}_icon.jpg`
  const fontColor = { color: `${player.classColor}` }
  const playerURL = `/${player.name}`

  return(
    <Link to={playerURL} style={{ textDecoration: 'none' }} >
    <div className='card'>
      <img className='card-img-top' src={artURL} alt='class art'/>
      <div className='card-body'>
        <h1 style={fontColor}>
          <img src={imageURL} alt='classIcon' className='class-icon'/>
          <br></br>
          {player.name}
        </h1>
      </div>
      <div>
      </div>
    </div>
    </Link>
  )
}
