import React from 'react';
import PlayerCards from '../components/PlayerCards';

export default function Home() {
  
  const logo = 'zuglife.png'

  return(
    <div>
      <img src={logo} alt='zug life' className="medium-logo"/>
      <p className= 'lifecraft'>
        <span className='red'>^ </span>We didn't choose the Zug Life<span className='red'> ^</span>
      </p>
      <div className= 'container container--large'>
        <PlayerCards />
      </div>
    </div>
  )
}
