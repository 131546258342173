const RESULTS = [
  {
    event: "Circuit 1",
    total_time: "2:42:11",
    dungeons: [
      { 
        name: "Deadmines",
        time: "20:04",
        points: 43
      },
      {
        name: "Wailing Caverns",
        time: "32:12",
        points: 42
      },
      {
        name: "Shadowfang Keep",
        time: "22:51",
        points: 44
      },
      {
        name: "Blackfathom Deeps",
        time: "46:04",
        points: 41
      },
    ],
    video: ""
  },
  {
    event: "Circuit 2",
    total_time: "1:42:21",
    dungeons: [
      { 
        name: "SM Graveyard",
        time: "05:39",
        points: 45
      },
      {
        name: "Razorfen Kraul",
        time: "22:12",
        points: 34
      },
      {
        name: "Gnomeregan",
        time: "25:24",
        points: 41
      },
      {
        name: "SM Library",
        time: "16:09",
        points: 45
      },
    ],
    video: ""
  },
  {
    event: "Circuit 3",
    total_time: "2:27:09",
    dungeons: [
      { 
        name: "Razorfen Downs",
        time: "24:01",
        points: 26
      },
      {
        name: "SM Armory",
        time: "11:45",
        points: 25
      },
      {
        name: "SM Cathedral",
        time: "14:23",
        points: 25
      },
      {
        name: "Uldaman",
        time: "50:46",
        points: 26
      },
    ],
    video: ""
  },
  {
    event: "Circuit 4",
    total_time: "4:24:34",
    dungeons: [
      { 
        name: "Zul'Farrak",
        time: "0:30:26",
        points: 27
      },
      {
        name: "Maraudon",
        time: "1:18:33",
        points: 25
      },
      {
        name: "Sunken Temple",
        time: "1:26:07",
        points: 27
      }
    ],
    video: ""
  }
]

export default RESULTS