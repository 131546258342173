import React from "react";

export default function Clips() {

  const marginStyle = {marginBottom: "4rem"}
  const containerStyle = { height: 720 }

  return(
    <div className="container container--large" style={marginStyle}>
      <h1 className='lifecraft-red'>Highlight Reel</h1>
      <div class="embed-responsive embed-responsive-16by9" style={containerStyle}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/T2OozINnrzo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <br></br>
      <br></br>
      <div class="embed-responsive embed-responsive-16by9" style={containerStyle}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PyjUNkKTlEo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  )
}