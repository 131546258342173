import React from "react";
import TwitchStream from "../components/TwitchStream";
import PLAYERS from '../data/players.ts';

export default function Streams() {

  const rowOne = PLAYERS.slice(0, 2)
  const rowTwo = PLAYERS.slice(2, 4)
  const rowThree = PLAYERS.slice(4, 6)
  const marginStyle = {marginBottom: "4rem"}


  return(
    <div className="container container--large" style={marginStyle}>
      <h1 className='lifecraft-red'>Streams</h1>
      <div className="row">
        {rowOne.map(player => {
          return <TwitchStream player={player}/>
          })
        }
      </div>
      <div className="row">
        {rowTwo.map(player => {
          return <TwitchStream player={player}/>
          })
        }
      </div>
      <div className="row">
        {rowThree.map(player => {
          return <TwitchStream player={player}/>
          })
        }
      </div>
    </div>
  )
}