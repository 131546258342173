import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './pages/Home';
import Streams from './pages/Streams';
import Clips from './pages/Clips'
import Scores from './pages/Scores';
import NavBar from './components/NavBar';
import PlayerInfo from './pages/PlayerInfo';
import Footer from './components/Footer';
import PLAYERS from './data/players.ts';
import { Route, Routes } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/streams" element={<Streams />} />
        <Route path="/clips" element={<Clips />} />
        {PLAYERS.map(player => {
          const playerPath = `/${player.name}`
          return <Route path={playerPath} element={<PlayerInfo player={player} />} />
        })}
        <Route path="/scores" element={<Scores />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
