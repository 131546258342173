const PLAYERS = [
  {
    name: 'Cheezey',
    class: 'druid',
    professions: ['engineering', 'mining'],
    stream: 'https://www.twitch.tv/cheezeygoodness',
    classColor: 'orange',
    channel: 'cheezeygoodness'
  },
  {
    name: 'Nomnom',
    class: 'rogue',
    professions: ['engineering', 'mining'],
    stream: 'https://www.twitch.tv/nomnomhc',
    classColor: 'yellow',
    channel: 'nomnomhc'
  },
  {
    name: 'Chadleyhank',
    class: 'warlock',
    professions: ['engineering', 'mining'],
    stream: 'https://www.twitch.tv/chadleyhank',
    classColor: 'purple',
    channel: 'Chadleyhank'
  },
  {
    name: 'Novahc',
    class: 'mage',
    professions: ['engineering', 'mining'],
    stream: 'https://www.twitch.tv/novahc_',
    classColor: 'lightBlue',
    channel: 'novahc_'
  },
  {
    name: 'Rosegoku',
    class: 'hunter',
    professions: ['engineering', 'mining'],
    stream: 'https://www.youtube.com/@rosegokuu683',
    classColor: 'green',
    channel: 'rosegokuu'
  },
  {
    name: 'Stonecoat',
    class: 'shaman',
    professions: ['engineering', 'mining'],
    stream: 'https://www.twitch.tv/stonecoat',
    classColor: 'blue',
    channel: 'stonecoat'
  }
]

export default PLAYERS;