import React from 'react'
import { TwitchEmbed } from 'react-twitch-embed'

export default function PlayerInfo({ player }) {
  const artURL = `class_art/${player.class}_art.png`
  const imageURL = `class_icons/${player.class}_icon.jpg`
  const engineeringImageURL = 'engineering_icon.jpg'
  const miningImageURL = 'mining_icon.jpg'
  const fontColor = { color: `${player.classColor}` }
  const containerStyle = { height: 720 }

  return (
      <div className="container">
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src={artURL} alt={player.name} className="rounded-circle" width="150"/>
                    <div className="mt-3">
                      <h1 style={fontColor}>{player.name}</h1>
                      <hr></hr>
                      <h1 style={fontColor}>
                        <img src={imageURL} alt='classIcon' className='class-icon'/>
                        {player.class}
                      </h1>
                      <h1 style={fontColor}>
                        <img src={engineeringImageURL} alt='engineeringIcon' className='profession-icon'/>
                        <img src={miningImageURL} alt='miningIcon' className='profession-icon'/>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={containerStyle}>
          <TwitchEmbed id={player.name} channel={player.channel} autoplay muted withChat={false} width={'100%'} height={'100%'} />
        </div>
      </div>
  )
}
