import React from 'react';
import Card from './Card';
import PLAYERS from '../data/players.ts';

export default function PlayerCards() {
  
  const marginStyle = {marginBottom: "4rem"}

  return(
    <div className="player-card-grid" style={marginStyle}>
      <div className='row'>
        {PLAYERS.slice(0, 3).map(player => {
            return <Card player={player} />
        })}
      </div>
      <div className='row'>
        {PLAYERS.slice(3, 6).map(player => {
            return <Card player={player} />
        })}
      </div>
    </div>
  )
}
