import React from "react";

export default function CircuitCard({ circuit }) {

  const cardColor = {backgroundColor: 'black'}
  const tableBodyTextColor = { color: 'white' }
  const tableHeaderTextColor = { color: 'red' }

  return(
    <div className='card' style={cardColor}>
      <h1 className='lifecraft-red'>
        {circuit.event}
      </h1>
      <table className='table'>
        <thead style={tableHeaderTextColor}>
          <tr>
            <th>Dungeon</th>
            <th>Clear Time</th>
            <th>Points Acquired</th>
          </tr>
        </thead>
        <tbody style={tableBodyTextColor}>
          {circuit.dungeons.map(dungeon =>{
            return(
              <tr>
                <td>{dungeon.name}</td>
                <td>{dungeon.time}</td>
                <td>{dungeon.points}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}