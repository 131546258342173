import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function NavBar() {
  
  const logo = 'zuglife.png'
  const navStyle = {width: '100vw', borderBottom: '1px solid white'}
  const textStyle = {color: '#9f2305'}

    return (
      <Navbar bg="dark" expand="lg" style={navStyle}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt='zug life' className="small-logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="streams" style={textStyle}>Streams</Nav.Link>
              <Nav.Link href="clips" style={textStyle}>Hardcore Clips</Nav.Link>
              <Nav.Link href="scores" style={textStyle}>Circuit Results</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}